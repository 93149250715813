body, * {
  margin: 0;
  padding: 0;
  font-family: Circular-Pro-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tmp input,
.tmp input:hover,
.tmp input:focus {
  width: 100%;
  border: none !important;
  border-width: 0 !important;
  font-size: 12px !important;
  outline-style: none !important;
}