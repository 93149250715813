.forms-content-wrapper {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    flex-direction: column;
    display: flex;
}

.form-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-grow: 1;
    height: 100vh;
    margin-top: -100px;
}

.form-wrapper .form {
    width: 90%;
    background-color: #f3f6f6;
    box-shadow: 0 10px 30px 0 rgba(0,0,0,.5);
    padding: 25px 25px;
    margin: 0 auto;
}

@media screen and (min-width: 435px) {
    .form-wrapper .form {
        width: 390px;
    }
}

.logo-wrapper {
    flex-basis: 100px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    display: flex;
}

.logo {
    width: 90px;
    display: block;
    margin: 0 auto;
}
